import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import './App.css';
import Autocomplete from '@mui/material/Autocomplete';

const countries = [
  { name: 'Germany', code: 'DE' },
  { name: 'Austria', code: 'AT' },
  { name: 'Czech Republic', code: 'CZ' },
];

function App() {
  const [loadingZip, setLoadingZip] = useState('90402');
  const [loadingCountry, setLoadingCountry] = useState(countries[0].code);
  const [unloadingZip, setUnloadingZip] = useState('91729');
  const [unloadingCountry, setUnloadingCountry] = useState(countries[0].code);
  const [originAddress, setOriginAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [distance, setDistance] = useState(0);
  const [loadingSuggestions, setLoadingSuggestions] = useState([]);
  const [unloadingSuggestions, setUnloadingSuggestions] = useState([]);

  const handleDistanceResponse = (response, status) => {
    console.log('handleDistanceResponse', response);

    if (['ZERO_RESULTS', 'NOT_FOUND'].includes(response.rows[0].elements[0].status)) {
      return;
    }

    setOriginAddress(response.originAddresses[0]);
    setDestinationAddress(response.destinationAddresses[0]);
    setDistance(response.rows[0].elements[0].distance.value);
  }

  const handleSubmit = () => {
    const origin = `${loadingZip}, ${countries.find(i => i.code === loadingCountry).name}`;
    const destination = `${unloadingZip}, ${countries.find(i => i.code === unloadingCountry).name}`;

    console.log('handleSubmit', origin, destination);

    window.distanceMatrixService.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
        unitSystem: 0,
        avoidHighways: false,
        avoidTolls: false,
      }, handleDistanceResponse);
  };

  const handleLoadingZipInput = (e) => {
    const value = e.target.value;
    getLocationSuggestions(value, setLoadingSuggestions);
  };

  const handleUnloadingZipInput = (e) => {
    const value = e.target.value;
    getLocationSuggestions(value, setUnloadingSuggestions);
  };

  const getLocationSuggestions = (query, setter) => {
    window.autocompleteService.getPlacePredictions({
      input: query,
      componentRestrictions: { country: loadingCountry },
    }, async (results) => {
      const suggestions = await handleLocationSuggestionResults(results);
      setter(suggestions);
    });
  };

  const handleLocationSuggestionResults = async (results) => {
    const list = results?.slice(0, 5) || [];
    const promises = list.map(item => getPlaceDetails(item.place_id));
    const suggestions = await Promise.all(promises);
    return suggestions.filter(i => i);
  };

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve) => {
      window.placesService.getDetails({
        placeId: placeId,
        fields: ['address_components'],
      }, (res) => {
        if (!res) {
          return;
        }
        const postalCode = res.address_components.find(i => i.types.includes('postal_code'))?.long_name;
        const city = res.address_components.find(i => i.types.includes('locality'))?.long_name;
        if (postalCode && city) {
          resolve({ postalCode, city });
        } else {
          resolve(null);
        }
      });
    });
  };

  const renderCountrySelect = (value, setter) => {
    return (
      <Select
        value={value}
        onChange={e => setter(e.target.value)}
      >
        {
          countries.map(country => (
            <MenuItem key={country.code} value={country.code}>
              {country.code} – {country.name}
            </MenuItem>
          ))
        }
      </Select>
    );
  };

  return (
    <div className='App'>
      <Stack direction='column' spacing={3}>
        <Stack direction='row' spacing={3}>
          {renderCountrySelect(loadingCountry, setLoadingCountry)}
          <Autocomplete
            filterOptions={(x) => x}
            options={loadingSuggestions.map((i) => `${i.postalCode}, ${i.city}`)}
            onInputChange={handleLoadingZipInput}
            onChange={(e, value) => setLoadingZip(value)}
            renderInput={(params) => <TextField {...params} style={{ width: '400px' }} label='Loading Zip Code' />}
          />
        </Stack>
        <Stack direction='row' spacing={3}>
          {renderCountrySelect(unloadingCountry, setUnloadingCountry)}
          <Autocomplete
            filterOptions={(x) => x}
            options={unloadingSuggestions.map((i) => `${i.postalCode}, ${i.city}`)}
            onInputChange={handleUnloadingZipInput}
            onChange={(e, value) => setUnloadingZip(value)}
            renderInput={(params) => <TextField {...params} style={{ width: '400px' }} label='Unloading Zip Code' />}
          />
        </Stack>
        <Button
          variant='contained'
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {
          !!distance && (
            <div>
              <div>Origin Address: {originAddress}</div>
              <div>Destination Address: {destinationAddress}</div>
              <div>Distance: {distance / 1000} km</div>
            </div>
          )
        }
      </Stack>
    </div>
  );
}

export default App;
